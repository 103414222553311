import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Context }  from './../Auth.Context';

export default function CustomerProtectedRoute() {
	const [userDetails,] = 	useContext(Context);

	if(!userDetails || !userDetails._id){
		return <Navigate to="/login" />;
		// return <Outlet />;
	}else{
		return <Outlet />;
	}
}// end CustomerProtectedRoute()

