import React, {lazy, Suspense} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CustomerProtectedRoute from './CustomerProtectedRoute';
import PublicOnlyRoute from './PublicOnlyRoute';

import { PRODUCT_GENDER_MALE, PRODUCT_GENDER_FEMALE } from './../config/global_constants';

import Layout from "./../layout/Layout";

const Home 				=	lazy(()=> import("./../components/Home"));
const ProductList 		=	lazy(()=> import("./../components/ProductList"));
const ProductDetails	=	lazy(()=> import("./../components/ProductDetails"));
const ForgotPassword	= 	lazy(()=> import("./../components/ForgotPassword"));
const Login 			=	lazy(()=> import("./../components/Login"));
const Signup 			=	lazy(()=> import("./../components/Signup"));
const Page404			=	lazy(()=> import("./../components/Page404"));
const Verify 			=	lazy(()=> import("./../components/verify"));
const ForgotVerify 		=	lazy(()=> import("./../components/ForgotVerify"));
const ResetPassword 	=	lazy(()=> import("./../components/ResetPassword"));
const Cart 				=	lazy(()=> import("./../components/Cart"));
const Checkout 			=	lazy(()=> import("./../components/Checkout"));
const HowItWorks		=	lazy(()=> import("./../components/HowItWorks"));
const AboutUs			=	lazy(()=> import("./../components/AboutUs"));
const ContactUs			=	lazy(()=> import("./../components/ContactUs"));
const HuugStudioForTag	=	lazy(()=> import("./../components/HuugStudioForTag"));
const HuugStudio		=	lazy(()=> import("./../components/HuugStudio"));
const ScrollToTop 		=	lazy(()=> import("./../ScrollToTop"));
const Category			=	lazy(()=> import("./../components/Category"));
const GenerateImageFromSearchTerm   =	lazy(()=> import("./../components/GenerateImageFromSearchTerm"));
const NewsLetter			=	lazy(()=> import("./../components/NewsLetter"));


const Blog			=	lazy(()=> import("./../components/Blog"));
const BlogDetails	=	lazy(()=> import("./../components/BlogDetails"));
const Careers		=	lazy(()=> import("./../components/Careers"));
const AsSeenIn		=	lazy(()=> import("./../components/AsSeenIn"));
const CmsPage		=	lazy(()=> import("./../components/CmsPage"));
const Faqs			=	lazy(()=> import("./../components/Faqs"));
const CollectionGenderWise	=	lazy(()=> import("./../components/CollectionGenderWise"));

const NewHuugStudio			=	lazy(()=> import("./../components/NewHuugStudio"));
const HuugStudioForProduct	=	lazy(()=> import("./../components/HuugStudioForProduct"));

const HtmlHuugStudio	=	lazy(()=> import("./../components/Html/HuugStudio"));
const HtmlMacmeriseStudio=	lazy(()=> import("./../components/Html/MacmeriseStudio"));

// const IndiyeStudioTshirt = lazy(()=> import("../components/IndiyeStudioTshirt"));
const IndiyeStudio = lazy(()=> import("../components/IndiyeStudio"));

/** Customer after login Pages */
const Notifications			=	lazy(()=> import("./../components/customer/Notifications"));
const WishList				=	lazy(()=> import("./../components/customer/WishList"));
const Address				=	lazy(()=> import("./../components/customer/Address"));
const Profile				=	lazy(()=> import("./../components/customer/Profile"));
const Orders				=	lazy(()=> import("./../components/customer/Orders"));
const OrdersRequest			=	lazy(()=> import("./../components/customer/order-request"));
const OrderDetails			=	lazy(()=> import("./../components/customer/OrderDetails"));
const OrderRequestDetails	=	lazy(()=> import("./../components/customer/OrderRequestDetails"));

const OrderConfirmPage	=	lazy(()=> import("./../components/customer/OrderConfirmPage"));

export default function AppRoutes() {
	return (
		<BrowserRouter>
			<Suspense fallback={<div></div>}>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="/collections" element={<ProductList />} />
						<Route path="/collections/:subcategory_slug" element={<ProductList />} />
						<Route path="/collections/:subcategory_slug/:slug" element={<ProductDetails />} />
						<Route path="/cart" element={<Cart />} />

						<Route path="/huug-studio/tag/:image_id" element={<HuugStudioForTag />} />
						<Route path="/huug-studio" element={<HuugStudio />} />

						<Route path="/search-term/:search_term" element={<GenerateImageFromSearchTerm />} />

						<Route path="/new-huug-studio/:image_id" element={<NewHuugStudio />} />
						<Route path="/huug-studio/product/:product_slug/:variation_id" element={<HuugStudioForProduct />} />

						<Route path="/for-men" element={<CollectionGenderWise collectionType={"for-men"} collectionGender={PRODUCT_GENDER_MALE} />} />
						<Route path="/for-women" element={<CollectionGenderWise collectionType={"for-women"} collectionGender={PRODUCT_GENDER_FEMALE} />} />

						<Route path="/for-men/:collection_slug" element={<ProductList collectionType={"for-men"} />} />
						<Route path="/for-men/:subcategory_slug" element={<ProductList collectionType={"for-men"} />} />

						<Route path="/for-women/:collection_slug" element={<ProductList collectionType={"for-women"} />} />
						<Route path="/for-women/:subcategory_slug" element={<ProductList collectionType={"for-women"} />} />

						<Route path="/for-men/:collection_slug/:slug" element={<ProductDetails collectionType={"for-men"} />} />
						<Route path="/for-women/:collection_slug/:slug" element={<ProductDetails collectionType={"for-women"} />} />

						<Route path="/how-it-works" element={<HowItWorks />} />
						<Route path="/contact-us" element={<ContactUs />} />
						<Route path="/about-us" element={<AboutUs />} />
						<Route path="/category" element={<Category />} />
						<Route path="/blog" element={<Blog />} />
						<Route path="/blog/:category_slug" element={<Blog />} />
						<Route path="/blog/:category_slug/:blog_slug" element={<BlogDetails />} />
						<Route path="/careers" element={<Careers />} />
						<Route path="/return-and-refund" element={<CmsPage pageSlug="return-and-refund" />}  />
						<Route path="/term-condition" element={<CmsPage pageSlug="term-condition" />}  />
						<Route path="/privacy-policies" element={<CmsPage pageSlug="privacy-policies" />}  />
						<Route path="/satisfaction-guarantee" element={<CmsPage pageSlug="satisfaction-guarantee" />}  />
						<Route path="/shipping" element={<CmsPage pageSlug="shipping" />}  />
						<Route path="/policies" element={<CmsPage pageSlug="policies" />}  />
						<Route path="/as-seen-in" element={<AsSeenIn />} />
						<Route path="/faqs" element={<Faqs />} />

						<Route path="/html/huug-studio" element={<HtmlHuugStudio />} />
						<Route path="/html/macmerise-studio" element={<HtmlMacmeriseStudio />} />

						<Route path="/indiye-studio/:search_term" element={<IndiyeStudio />} />
						<Route path="/newsletter-unsubscribe/:enc_id" element={<NewsLetter />} />
						<Route path="/customer/checkout" element={<Checkout />} />

						

						<Route element={ <PublicOnlyRoute/> }>
							<Route path="/login" element={<Login />} />

							<Route path="/sign-up" element={<Signup />} />
							<Route path="/verify/:validate_string" element={<Verify />} />

							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/forgot-verify/:validate_string" element={<ForgotVerify />} />
							<Route path="/reset-password/:validate_string" element={<ResetPassword />} />
						</Route>

						<Route path="customer" element={ <CustomerProtectedRoute/> }>
							<Route index={true} element={  <Profile /> }/>
							<Route path="profile" element={  <Profile /> }/>
							{/* <Route path="checkout" element={<Checkout />} /> */}
							<Route path="notifications" element={<Notifications />} />
							<Route path="wish-list" element={<WishList />} />
							<Route path="address" element={<Address />} />
							<Route path="orders" element={<Orders />} />
							<Route path="order-request" element={<OrdersRequest />} />
							
							<Route path="orders/:order_id" element={<OrderDetails />} />
							<Route path="order-request/:unique_id" element={<OrderRequestDetails />} />

							
							<Route path="order-confirmation/:order_id" element={<OrderConfirmPage />} />
						</Route>

						<Route path="*" element={<Page404 />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}
